<script lang="ts" setup>
import { ref, watch } from 'vue'
import type { DropdownInstance } from 'element-plus'
import { useTripStore } from '@/store/trip'
import UserAvatar from '@/assets/images/user-avatar.png'
const dropdown1 = ref<DropdownInstance>()

const tripStore = useTripStore()
const { data, status, signIn, signOut } = useAuth()
const route = useRoute()

function showClick () {
  if (!dropdown1.value) { return }
  dropdown1.value.handleClose()
}

async function logOut () {
  await signOut({redirect: false })

 return navigateTo('/', {external: true})
}

</script>
<template>
  <el-header>
    <div class="container">
      <div class="header-container">
        <NuxtLink to="/">
          <img src="@/assets/images/kayo-logo.png" class="logo" alt="Kayo logo">
        </NuxtLink>
        <div class="user-panel">
          <div>
            <template v-if="tripStore.buyTripIdEnter && !route.fullPath.includes('trip/')">
              <NuxtLink :to="`/trip/${tripStore.buyTripIdEnter}`" class="btn btn-md mr-2 kj-btn-transparent hidden-xs-only">
                {{ $t('header.powrot_do_rezerwacji') }}
              </NuxtLink>
            </template>
            <NuxtLink to="/" class="btn btn-md kj-btn-transparent hidden-xs-only">
              {{ $t('header.moje_wyjazdy') }}
            </NuxtLink>
          </div>
          <div class="user-panel__actions">
            <client-only>
              <el-dropdown ref="dropdown1" trigger="click" popper-class="user-dropdown">
                <span class="el-dropdown-link">
                  <Avatar :image-url="UserAvatar" />
                </span>
                <template #dropdown>
                  <el-dropdown-menu @click="showClick">
                    <el-dropdown-item class="hidden-sm-and-up">
                      <template v-if="tripStore.buyTripIdEnter && !route.fullPath.includes('trip/')">
                        <NuxtLink :to="`/trip/${tripStore.buyTripIdEnter}`">
                          {{ $t('header.powrot_do_rezerwacji') }}
                        </NuxtLink>
                      </template>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <NuxtLink to="/user/edit">
                        {{ $t('header.edycja_profilu') }}
                      </NuxtLink>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <NuxtLink to="/user/edit-password">
                        {{ $t('header.zmien_haslo') }}
                      </NuxtLink>
                    </el-dropdown-item>
                    <el-dropdown-item class="hidden-sm-and-up">
                      <NuxtLink to="/">
                        {{ $t('header.moje_wyjazdy') }}
                      </NuxtLink>
                    </el-dropdown-item>
                    <div class="text-center">
                      <el-button class="btn-md" @click="logOut()">
                        {{ $t('header.wyloguj') }}
                      </el-button>
                    </div>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </client-only>
          </div>
        </div>
      </div>
    </div>
  </el-header>
</template>
